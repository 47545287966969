@charset "UTF-8";
@use "../../../../mixin.scss" as *;
@use "../../../../mixin.scss" as mx;
@use "../../../../setting.scss" as st;
@import "../../../../setting.scss";
@import "../../../../common.scss";
@import "../../../../form.scss";

.add_authorization_dialog {
  .dialog {
    .dialog_body {
      padding: 0 30px 32px;
    }
  }
}

.add_authorization_dialog_inner {
  padding: 1rem;
  height: 450px;

  &.add {
    height: 100%;
  }

  .header_cnt {
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .syntax_height_lighter_cnt {
    pre {
      height: 331px;
    }
  }

  .cm-editor {
    &.ͼ1 {
      outline: 1px dotted;

      &.alert {
        outline: 1px dotted $error_text_color;
      }

      &.cm-focused {
        outline: 1px dotted;
      }
    }
  }

  .code_editor_cnt {
    .alert_box {
      display: none;
    }

    &.alert {
      position: relative;

      .alert_box {
        position: absolute;
        display: block;
        bottom: 2px;
        right: 6px;
        padding: 0.25rem 0.5rem;
        background: rgba($error_text_color, 0.1);
        border: 1px solid rgba($error_text_color, 0.3);
        font-size: 10px;
      }
    }
  }

  .tab_btn_box {
    margin-bottom: 1rem;
    display: flex;

    button {
      box-sizing: border-box;
      background: transparent;
      border: 2px solid $border_default_color;
      color: rgba($font_color, 0.7);

      &.selected {
        background: rgba($site_color, 0.1);
        border: 2px solid rgba($site_color, 0.6);
        color: $site_color;

      }
    }

    :nth-child(1) {
      border-radius: 4px 0px 0px 4px;
      border-right: none;
      padding-right: 8px;

      &.selected {
        padding-right: 6px;
      }
    }

    :nth-child(2) {
      border-radius: 0px 4px 4px 0px;
      margin-left: 0px;
      border-left: none;
      padding-left: 8px;

      &.selected {
        padding-left: 6px;
      }
    }
  }
}

.add_authorization_dialog_footer {
  padding-top: 0.5rem;
  height: 100%;
  align-items: center;
}

.edit_wrap {
  max-width: 870px;
  margin: 0 auto;

  h3 {
    color: $font_color;
    font-size: 16px;
    margin-bottom: 5px;
  }

  .edit_box {
    @include border-default($color: $border_edit_wrap_color);
    padding: 5px;
    // margin-bottom: 60px;

    &.for_cadect {
      padding: 20px 20px 10px;
      margin-top: 25px;
      margin-bottom: 15px;
      position: relative;

      .item_wrap.fix {
        position: absolute;
        background-color: #fff;
        height: 50px;
        top: -30px;
        left: 20px;
      }
    }

    .item_cnt {
      margin-left: 1rem;
    }

    &.number_box {
      padding: 40px 20px 20px;
      margin-bottom: 40px;
      position: relative;

      .item_wrap.fix {
        position: absolute;
        background-color: #fff;
        height: 50px;
        top: -25px;
        left: 30px;

        .item_head {
          background-color: #fff;
          padding: 20px 15px 20px 5px;
          width: auto;

          span.required {
            margin-left: 15px;
          }
        }

        .item_cnt {
          display: flex;
          align-items: flex-end;
          padding: 0;

          input {
            width: 90px;
          }
        }
      }

      .item_cnt {
        display: flex;
        align-items: flex-end;

        input {
          width: 90px;
        }
      }

      .number_box {
        margin-top: 50px;
        margin-bottom: 0;
      }
    }

    .item_wrap {
      display: flex;

      .item_head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 200px;
        font-size: 14px;
        font-weight: bold;
        padding: 20px 15px;
        background-color: $item_head_background_color;

        .comment {
          display: inline-block;
          font-size: 12px;
          font-weight: normal;
        }

        &.plus_comment {
          height: 82px;

          +.item_cnt {
            input {
              margin-top: 15px;
            }
          }
        }
      }

      .item_cnt {
        flex: 1;
        font-size: 14px;

        input,
        select {
          margin: 5px 0;
        }

        .indent {
          margin-left: 36px;
        }

        &.flex {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
        }

        .postal_coad {
          width: 120px;
        }

        label {
          display: inline-block;
          font-size: 14px;
          font-weight: bold;
          margin-right: 10px;

          &.fix_size {
            display: inline-block;
            width: 34px;
            text-align: right;
          }

          &.unit {
            margin-left: 5px;
            font-size: 14px;
            vertical-align: bottom;
            line-height: 26px;
          }
        }

        &.label_normal {
          label {
            font-weight: normal;
          }
        }

        input+label.fix_size {
          margin-left: 30px;
        }

        .comment {
          display: inline-block;
          margin-left: 10px;
          font-size: 12px;
        }
      }
    }
  }

  .date_box {
    margin-bottom: 50px;

    label {
      font-size: 14px;
    }

    .input_wrap {
      position: relative;
      display: inline-block;

      &.disabled {
        background-color: transparent;
      }

      input[type="date"] {
        background-color: transparent;
        padding-right: 0;
        border: none;

        &::-webkit-calendar-picker-indicator {
          opacity: 0;
          position: relative;
          background-color: #ff0000;
          width: 25px;
          height: 25px;
          z-index: 2;
          cursor: pointer;
        }
      }

      img.calendar {
        z-index: 1;
        position: absolute;
        top: 7px;
        right: 11px;
      }

      img.reset {
        width: 20px;
        z-index: 1;
        position: absolute;
        top: 10px;
        right: 42px;
        cursor: pointer;
      }
    }
  }
}

.json_upload_dialog {
  .dialog {
    width: 62.5vw;
    max-width: 800px;
    height: 340px;

    &_body {
      padding: 1em !important;

      .inner {
        $border_unit_size: 1rem;
        $border_width: 2px;
        $fill_size: 0.5rem;
        $fill_color: #e2e2e3;
        background-image: linear-gradient(to right,
            $fill_color,
            $fill_color $fill_size,
            transparent $fill_size,
            transparent $border_unit_size ),
          linear-gradient(to right,
            $fill_color,
            $fill_color $fill_size,
            transparent $fill_size,
            transparent $border_unit_size ),
          linear-gradient(to bottom,
            $fill_color,
            $fill_color $fill_size,
            transparent $fill_size,
            transparent $border_unit_size ),
          linear-gradient(to bottom,
            $fill_color,
            $fill_color $fill_size,
            transparent $fill_size,
            transparent $border_unit_size );
        background-size: $border_unit_size $border_width,
          $border_unit_size $border_width, $border_width $border_unit_size,
          $border_width $border_unit_size;
        background-position: left top, left bottom, left top, right top;
        background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
      }
    }
  }

  &__body {
    font-size: 1.25rem;
    @include mx.flex_box;
    @include mx.flex_direction_column;
    height: 100%;
    @include mx.flex_all_center;

    &.draggable {
      background-color: rgba($color: st.$site_color, $alpha: 0.15);
    }

    >div {
      &:last-of-type {
        @include mx.flex_box;
        @include mx.flex_direction_column;
        width: 10.5em;
        margin-top: 2em;
        gap: 1em;

        button {
          font-size: 100%;
          padding: 0.5em 1.5em;
          height: max-content;
          width: 100%;

          &+button {
            margin-left: 0 !important;
          }
        }
      }
    }
  }

  /* .csv_upload_dialog */
}

// テンプレート適用
.apply_template_dialog {
  .dialog {
    gap: 2rem 0;

    &_body {
      font-size: 1rem;

      .inner {
        @include mx.flex_box;
        @include mx.flex_direction_column;
        gap: 2rem 0;
      }
    }
  }

  &__footer {
    @include mx.flex_box;

    >div {
      flex: 1;

      button {
        min-width: initial;
        width: 50%;
        margin: 0 !important;
      }

      &:last-of-type {
        @include mx.flex_box;
        gap: 0 15px;
      }
    }
  }
}

// テンプレート保存・編集
.sample_add_dialog,
.sample_edit_dialog {
  .dialog {
    gap: 2rem 0;

    &_body {
      font-size: 18px !important;

      .inner {
        @include mx.flex_box;
        @include mx.flex_direction_column;
        @include mx.flex_align_center;
        gap: 2rem 0;

        .input_wrap {
          width: 100%;

          input {
            font-size: calc(1em - 4px);
            width: 100%;
            line-height: calc(3em - 4px);
          }
        }
      }
    }
  }
}

.sample_edit_dialog {
  .dialog {
    &_body {
      .inner {
        padding: 0 2rem;

        div+div {
          @include mx.flex_box;
          @include mx.flex_align_center;
          width: 100%;
          gap: 1rem;

          .input_wrap {
            flex: 1;
          }

          button {
            min-width: initial;
            width: calc(5em + 2px);
          }
        }
      }
    }
  }
}