@charset "UTF-8";
@import "../../../setting.scss";
@import "../../../mixin.scss";

button {
    font-family: inherit;
    @include radius(2px);

    &:hover {
        opacity: 0.75;
    }

    &.small {
        min-width: 44px;
        line-height: 25px;
        font-size: 12px;
    }

    &.medium {
        min-width: 100px;
        height: 35px;
        font-size: 14px;
    }

    &.large {
        min-width: 170px;
        height: 45px;
        font-size: 16px;
    }


    &.primary {
        background-color: $btn_primary_color;
        color: #FFF;
    }

    &.secondary {
        background-color: $btn_secondary_color;
        color: #FFF;
    }

    &.tertiary {
        background-color: $btn_tertiary_color;
        border: 1px solid $site_color;
        color: $site_color;
    }

    &.quaternary {
        background-color: #AF0C0C;
        border-color: transparent;
        color: #fff;
    }

    &.alert {
        background-color: $accent_color;
        color: #fff;
    }

    &.reset {
        position: absolute;
        top: 21px;
        left: calc(70% - 53px);
        background-color: inherit !important;
        color: $font_color;
        min-width: auto;
        width: 20px;
        height: 20px;
        padding: 0;
        line-height: 20px;
        font-size: 20px;
        background-image: url('../../../assets/images/common/cross_on.svg');
    }

    /* アイコンあり */
    &.icon_button {
        @include flex_box;
        @include flex_all_center;
        gap: 0.25em;

        .button_icon {
            display: inline-block;
            height: 1.0777em;
            line-height: 1;

            >* {
                height: 100%;
                vertical-align: baseline;
            }
        }
    }

    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}

button.small+button.small {
    margin-left: 5px;
}

button.medium+button.medium {
    margin-left: 20px;
}

button.large+button.large {
    margin-left: 15px;
}

button.add_arrow {
    position: relative;

    &::before {
        position: absolute;
        top: 4px;
        left: 0;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 0 6px 6px;
        border-color: transparent transparent transparent $site_color;
        pointer-events: none;
    }

    margin-left: 20px;
    padding-left: 20px;
    text-decoration: underline;

}