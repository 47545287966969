@charset "utf-8";
@use '../../../mixin.scss' as mx;
@import '../../../setting.scss';

.dialog:has(.member_select_dialog__body) {
  height: 80%;
  max-height: 810px !important;

  .dialog_body {
    height: 100%;
    padding-bottom: 24px;

    .inner {
      height: 100%;

      .member_select_dialog__body {
        height: 100%;

        .main_cnt_wrap {
          height: calc(100% - 95px);

          .table_box {
            height: calc(100% - 120px);
          }
        }
      }
    }
  }
}

.member_select_dialog {
  &__body {

    .member_cnt {
      border: 1px solid #E2E2E3;
      border-radius: 5px;
      margin-top: 1rem;
      width: 100%;
      height: 65px;
      min-height: 65px;
      padding: 0.5rem;
      display: flex;
      gap: 0.25rem;
      flex-wrap: wrap;

      button {
        border-radius: 4px;
        height: 24px;
        margin-right: 5px;
        margin-bottom: 5px;
        padding: 0 5px;
        font-size: 12px;
        background-color: $worker_button_color;

        &:hover {
          background-color: $worker_button_hover_color;
        }

        &.active {
          color: #FFF;
          background-color: $worker_button_active_color;
        }
      }
    }

    .select_button_cnt {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-top: 1rem;
      margin-bottom: 1rem;
      gap: 4rem;
      height: 23px;
    }

    .main_cnt_wrap {
      // max-height: calc(40vh - 56px);
      // min-height: calc(40vh - 56px);
      overflow: auto;
    }

    .table_box {
      border: 2px solid #E2E2E3;
      overflow: auto;
      border: 2px solid #E2E2E3;

      .table_wrapper {
        overflow: auto;
        border: none;

        table {
          border: none;

          td,
          th,
          tr {
            border-right: none !important;
            border-left: none !important;

            td {
              border-bottom: 1px solid #E2E2E3 !important;
            }
          }

          th {
            border-top: none !important;
          }
        }
      }

    }

  }

  &__actions {
    font-size: 1rem;
    margin-top: 2rem;
    @include mx.flex_box;
    @include mx.flex_content_center;
    gap: 0 1em;
    height: 45px;
    margin-bottom: none;

    button {
      font-size: 100%;
      margin: 0 !important;
      width: 10.5em;
      height: calc(3em - 3px);
    }
  }
}