@use '../../../mixin' as mx;
@use '../../../setting' as st;

.request_setting {
  .main_cnt {
    min-width: 530px;
  }

  &__body {
    padding: calc(2em + 2px);
    @include mx.flex_box;
    @include mx.flex_direction_column;
    gap: 3em;
    position: relative;

    &__wrap {
      padding: 0 clamp(1rem, 5vw, 5rem);
    }

    .button_cnt {
      text-align: center;
      margin-top: 2rem;
    }

    .member_cnt {
      border: 1px solid #E2E2E3;
      border-radius: 5px;
      margin-top: 1rem;
      width: 100%;
      padding: 0.5rem;
      display: flex;
      gap: 0.25rem;
      flex-wrap: wrap;

      .member_card {
        display: flex;
        align-items: center;
        padding: 4px 8px;
        background: #D5E9F0;
        border-radius: 4px;
        height: 24px;
        font-size: 16px;
      }
    }
  }
}