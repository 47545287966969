@use '../../../mixin' as mx;
@use '../../../setting' as st;

.notification_settings {
  .inner {
    .inner {
      gap: 40px 0;

      >section {
        flex: 1;
        @include mx.flex_box;
        @include mx.flex_direction_column;
        overflow: auto;
      }
    }
  }

  &__body {
    flex: 1;
    @include mx.border-default($color: st.$border_edit_wrap_color);
    padding: 5px;
    display: grid;
    grid-template-columns: minmax(230px, 22.3%) 1fr;
    gap: 15px;
    overflow: auto;
  }

  // 左側
  &__search {
    $padding_offset: 4px;
    @include mx.flex_box;
    @include mx.flex_direction_column;
    background-color: #ebebeb;
    padding: 1.5em calc(1em - #{$padding_offset}) 1.5em 1em;
    // padding: 1.5em 1em;
    gap: 10px 0;
    overflow: hidden;

    &__header {
      font-weight: bold;
      @include mx.flex_box;
      @include mx.flex_all_center;
      gap: 0 0.25em;

      img {
        width: 1.25em;
      }
    }

    &__body {
      flex: 1;
      overflow: auto;
      padding-right: $padding_offset;
    }

    &__forms {
      @include mx.flex_box;
      @include mx.flex_direction_column;
      font-size: 0.75rem;
      gap: 0.75em 0;

      .labeled_form {
        width: 100%;

        .select,
        // .date_picker,
        .input_date,
        .react_select {
          width: 100%;
          // height: 3em;
          border: none;

          * {
            border: inherit;
          }
        }

        &__label {
          font-size: 100% !important;
          line-height: 2 !important;
        }

        .select {
          background-color: #fff;

          select {
            height: 100%;
          }
        }

        .input_date {
          height: 3em;
          max-width: 16.5em;
        }
      }

      &__toggle {
        @include mx.flex_box;
        @include mx.flex_all_center;
        gap: 0 0.5em;
        margin: auto;
        color: st.$site_color;
        font-weight: bold;
        cursor: pointer;

        .up_down_toggle {
          position: relative;

          &::after {
            font-size: calc(1em - 2px);
          }
        }

        &.close {
          .up_down_toggle {
            transform: rotateZ(180deg);
          }
        }
      }

      &:not(:first-of-type) {
        margin-top: 10px;
      }
    }

    &__bottom {
      @include mx.flex_box;
      @include mx.flex_direction_column;
      gap: 10px;

      button {
        width: 100%;
        height: 2.5em;
        margin: 0 !important;
        min-width: auto;
      }

      >div {
        &:nth-of-type(2) {
          @include mx.flex_box;
          gap: 10px;
        }
      }
    }

    .specify_template_button {
      position: relative;
      width: 100%;

      >button {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      &__list {
        position: fixed;
        display: block;
        max-height: 11.35em;
        padding: 0.5em 0;
        // top: 100%;
        // left: 0;
        border: 1px solid st.$site_color;
        border-top-color: transparent;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        background-color: #fff;
        overflow: auto;
        z-index: 1;

        &.hidden {
          display: none;
        }

        &__item {
          font-size: 12px;
          @include mx.flex_box;
          @include mx.flex_align_center;
          line-height: 2.5;

          &:hover {
            >button {
              &:first-of-type {
                color: st.$site_color;
              }
            }
          }

          >button {
            &:hover {
              opacity: 1;
            }

            &:first-of-type {
              flex: 1;
              @include mx.longTextEllipsis;
              padding: 0 0 0 0.5em;
              text-align: left;
            }

            &:last-of-type {
              width: max-content;

              img {
                width: 2em;
                transition: 0.2s;
              }

              &:hover {
                img {
                  transform: scale(1.2);
                }
              }
            }

            &.canceled {
              opacity: 0.2;
              cursor: default;

              &:hover {
                img {
                  transform: none;
                }
              }
            }
          }
        }
      }
    }
  }

  // 右側
  &__users {
    @include mx.flex_box;
    @include mx.flex_direction_column;
    overflow: hidden;

    >section {
      flex: 1;
      @include mx.flex_box;
      @include mx.flex_direction_column;
      overflow: hidden;

      >header {
        white-space: nowrap;
      }
    }

    &__table {
      flex: 1;
      @include mx.border-default($color: st.$border_edit_wrap_color);
      padding: 5px;
      overflow: hidden;

      .table_responsive {
        height: 100%;

        .table {
          table-layout: fixed;

          th,
          td {
            &:first-of-type {
              width: 6em;

              .responsive_checkbox {
                width: 100%;
                @include mx.flex_content_center;
              }
            }
          }

          tbody {
            tr {
              &:last-of-type {
                td {
                  border-bottom: 1px solid #e2e2e3 !important;
                }
              }
            }
          }
        }
      }

      th {
        font-weight: normal;
      }
    }

    &__transfer {
      font-size: 2.5rem;
      @include mx.flex_box;
      @include mx.flex_all_center;
      gap: 2em;

      .up_down_toggle {
        font-size: 100%;
        transform: translateY(60%);

        &::before {
          border-radius: 2px;
        }

        &.close {
          &::after {
            transform: translateY(28%) rotateZ(180deg);
          }
        }
      }
    }
  }
}

// csv取り込み
.csv_upload_dialog {
  .dialog {
    width: 62.5vw;
    max-width: 800px;
    height: 340px;

    &_body {
      padding: 1em !important;

      .inner {
        $border_unit_size: 1rem;
        $border_width: 2px;
        $fill_size: 0.5rem;
        $fill_color: #e2e2e3;
        background-image: linear-gradient(to right,
            $fill_color,
            $fill_color $fill_size,
            transparent $fill_size,
            transparent $border_unit_size ),
          linear-gradient(to right,
            $fill_color,
            $fill_color $fill_size,
            transparent $fill_size,
            transparent $border_unit_size ),
          linear-gradient(to bottom,
            $fill_color,
            $fill_color $fill_size,
            transparent $fill_size,
            transparent $border_unit_size ),
          linear-gradient(to bottom,
            $fill_color,
            $fill_color $fill_size,
            transparent $fill_size,
            transparent $border_unit_size );
        background-size: $border_unit_size $border_width,
          $border_unit_size $border_width, $border_width $border_unit_size,
          $border_width $border_unit_size;
        background-position: left top, left bottom, left top, right top;
        background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
      }
    }
  }

  &__body {
    font-size: 1.25rem;
    @include mx.flex_box;
    @include mx.flex_direction_column;
    height: 100%;
    @include mx.flex_all_center;

    &.draggable {
      background-color: rgba($color: st.$site_color, $alpha: 0.15);
    }

    >div {
      &:last-of-type {
        @include mx.flex_box;
        @include mx.flex_direction_column;
        width: 10.5em;
        margin-top: 2em;
        gap: 1em;

        button {
          font-size: 100%;
          padding: 0.5em 1.5em;
          height: max-content;
          width: 100%;

          &+button {
            margin-left: 0 !important;
          }
        }
      }
    }
  }

  /* .csv_upload_dialog */
}

// テンプレート適用
.apply_template_dialog {
  .dialog {
    gap: 2rem 0;

    &_body {
      font-size: 1rem;

      .inner {
        @include mx.flex_box;
        @include mx.flex_direction_column;
        gap: 2rem 0;
      }
    }
  }

  &__footer {
    @include mx.flex_box;

    >div {
      flex: 1;

      button {
        min-width: initial;
        width: 50%;
        margin: 0 !important;
      }

      &:last-of-type {
        @include mx.flex_box;
        gap: 0 15px;
      }
    }
  }
}

// テンプレート保存・編集
.sample_add_dialog,
.sample_edit_dialog {
  .dialog {
    gap: 2rem 0;

    &_body {
      font-size: 18px !important;

      .inner {
        @include mx.flex_box;
        @include mx.flex_direction_column;
        @include mx.flex_align_center;
        gap: 2rem 0;

        .input_wrap {
          width: 100%;

          input {
            font-size: calc(1em - 4px);
            width: 100%;
            line-height: calc(3em - 4px);
          }
        }
      }
    }
  }
}

.sample_edit_dialog {
  .dialog {
    &_body {
      .inner {
        padding: 0 2rem;

        div+div {
          @include mx.flex_box;
          @include mx.flex_align_center;
          width: 100%;
          gap: 1rem;

          .input_wrap {
            flex: 1;
          }

          button {
            min-width: initial;
            width: calc(5em + 2px);
          }
        }
      }
    }
  }
}