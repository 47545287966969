@charset "UTF-8";
@import "./setting.scss";
@import "./mixin.scss";
// @import "./common.scss";
@import "./form.scss";

#App {
  .tip_box {
    display: flex;
    flex-wrap: wrap;
    //align-items: start;
    justify-content: center;
    max-width: 930px;
    margin: 0 auto;

    .tip_wraper {
      //width: calc(100% / 3);
      width: 310px;

      >div {
        height: calc(100% - 20px);
        @include radius(4px);
        margin: 10px;
        padding: 20px 15px 0;
      }

      hr {
        background-color: $site_color;
        margin: 25px 0 20px;
      }

      .tip {
        background-color: #FFF;

        h3 {
          font-size: 18px;
          font-weight: bold;
        }

        .text_box {
          .count {
            font-weight: bold;

            span {
              display: inline-block;
              font-weight: bold;
              vertical-align: middle;
            }
          }
        }
      }
    }

    .tip_wraper.tip_nums {
      .tip {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .text_box {
          flex: 1;
          margin-left: 15px;

          &.second {
            margin-left: 0;
          }

          .count {
            text-align: right;
            font-size: 40px;
            line-height: 30px;

            span {
              font-size: 18px;
              margin-left: 5px;
              margin-top: 24px;
            }
          }
        }
      }
    }

    .tip_wraper.tip_storege {
      .tip {
        display: flex;
        flex-direction: column;
        position: relative;
        text-align: center;
        align-items: center;

        h3 {
          line-height: 24px;
          height: 70px;
          display: flex;
          align-items: center;
        }

        .text_box {
          position: absolute;
          //background-color: #FFFFF0;
          bottom: 56px;
          left: 50%;
          margin-left: -50px;
          width: 100px;

          h4 {
            color: #333333;
            font-size: 16px;
            margin-bottom: 10px;
          }

          .count {
            font-size: 25px;
            line-height: 25px;

            span {
              font-size: 16px;
              margin-left: 4px;
              margin-top: 6px;
            }
          }

          .total {
            font-size: 12px;
            font-weight: bold;
          }
        }
      }
    }
  }
}